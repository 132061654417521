import "./timer.scss";
import { RecordingIcon, RecordingPaused, RecordIcon, TickIcon } from "assets/index";
import { formatTime, timeToSeconds } from "services/timerServices";

export const Timer = ({ isRecording, isPaused, time, sessionView }) => {

    const prevTime = sessionView.duration ? timeToSeconds(sessionView.duration) : 0;

    return (
        <div
            className={`timerContainer ${isRecording
                ? isPaused
                    ? "paused"
                    : "recording"
                : time > 0
                    ? "completed"
                    : "stopped"
                }`}
        >
            {isRecording ? (
                isPaused ? (
                    <img src={RecordingPaused} alt="Recording Paused" />
                ) : (
                    <img src={RecordingIcon} alt="Recording Status" />
                )
            ) : time > 0 ? (
                <img src={TickIcon} alt="Recording Stopped" />
            ) : (
                <img src={RecordIcon} alt="Idle Record Status" />
            )}
            <p>{isRecording ? formatTime(time) : formatTime(prevTime)}</p>
        </div>
    );
};