// Function to search sessions by query
export const searchSessions = (sessions, query) => {
  if (!sessions) return {};

  try {
    const searchedSessions = Object.keys(sessions).reduce((searched, date) => {
      const session = sessions[date];
      const phnString = session?.phn?.toString();

      const matchesQuery =
        session?.patientName?.toLowerCase().includes(query.toLowerCase()) ||
        (phnString && phnString.includes(query));

      if (matchesQuery) {
        searched[date] = session;
      }

      return searched;
    }, {});

    return searchedSessions;
  } catch (error) {
    console.error("Error searching sessions:", error);
    return {};
  }
};


// Function to filter sessions by the selected tab (recent or trash)
export const filterSessionsByTab = (sessions, selectedTab) => {
  if (!sessions) return {};

  try {
    // Filter sessions based on selected tab
    const filteredSessions = Object.keys(sessions).reduce((filtered, date) => {
      const session = sessions[date];

      const matchesTab =
        (selectedTab === "recent" && session.live) ||
        (selectedTab === "trash" && !session.live);

      if (matchesTab) {
        filtered[date] = session;
      }

      return filtered;
    }, {});

    return filteredSessions;
  } catch (error) {
    console.error("Error filtering sessions:", error);
    return {};
  }
};

// Format time
export const formatDate = (timestamp) => {
  try {
    const date = new Date(Number(timestamp));
    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12;
    hours = hours.toString().padStart(2, "0");
    return `${hours}:${minutes} ${ampm}`;
  } catch (error) {
    console.error("Error formatting date:", error);
    return "";
  }
};

// Function to get stored sessions from localStorage
export const getStoredSessions = () => {
  try {
    return JSON.parse(localStorage.getItem("sessions")) || {};
  } catch (error) {
    console.error("Error getting stored sessions:", error);
    return {};
  }
};

// Function to save sessions to localStorage
export const saveToLocalStorage = (updatedSessions) => {
  try {
    localStorage.setItem("sessions", JSON.stringify(updatedSessions));
  } catch (error) {
    console.error("Error saving to localStorage:", error);
  }
};

// Function to create a new session
export const handleNewSession = (patientName, phn) => {
  try {
    const sessions = getStoredSessions();

    const newSession = {
      id: Date.now(),
      patientName,
      phn,
      dictation: "",
      live: true,
      status: "",
      SOAP: {
        Subjective: "",
        Objective: "",
        Assessment: "",
        Plan: "",
      },
      duration: "",
    };

    sessions[newSession.id] = newSession;
    saveToLocalStorage(sessions);
    return newSession;
  } catch (error) {
    console.error("Error creating new session:", error);
    return null;
  }
};

// Function to update session status (e.g., live or not)
export const updateSessionStatus = (sessions, key, status) => {
  try {
    const updatedSessions = { ...sessions };
    if (updatedSessions[key]) {
      updatedSessions[key].live = status;
      saveToLocalStorage(updatedSessions);
    } else {
      console.warn("Session not found for updating status");
    }
  } catch (error) {
    console.error("Error updating session status:", error);
  }
};

// Function to handle session editing
export const handleSessionEdit = (
  sessionName,
  patientName,
  PHN,
  setSessionName,
  setPatientName,
  setPHN,
  setIsEditing
) => {
  try {
    setSessionName(sessionName);
    setPatientName(patientName);
    setPHN(PHN);
    setIsEditing(true);
  } catch (error) {
    console.error("Error handling session edit:", error);
  }
};

// Function to save the edited session
export const handleSessionSave = (
  sessions,
  sessionKey,
  updatedSessionData,
  setIsEditing
) => {
  try {
    const updatedSessions = { ...sessions };
    if (updatedSessions[sessionKey]) {
      updatedSessions[sessionKey] = {
        ...updatedSessions[sessionKey],
        ...updatedSessionData,
      };
      saveToLocalStorage(updatedSessions);
      setIsEditing(false);
    } else {
      console.warn("Session not found for saving");
    }
  } catch (error) {
    console.error("Error saving session:", error);
  }
};

// Function to cancel the editing process and revert changes
export const handleSessionCancel = (
  initialSessionName,
  initialPatientName,
  initialPHN,
  setSessionName,
  setPatientName,
  setPHN,
  setIsEditing
) => {
  try {
    setSessionName(initialSessionName);
    setPatientName(initialPatientName);
    setPHN(initialPHN);
    setIsEditing(false);
  } catch (error) {
    console.error("Error canceling session edit:", error);
  }
};

// Function to update session details
export const updateSessionDetails = (sessionKey, updatedData) => {
  try {
    const sessions = getStoredSessions();
    if (sessions[sessionKey]) {
      sessions[sessionKey] = {
        ...sessions[sessionKey],
        ...updatedData,
      };
      saveToLocalStorage(sessions);
    } else {
      console.error("Session not found for updating details");
    }
  } catch (error) {
    console.error("Error updating session details:", error);
  }
};
