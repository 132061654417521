import { useState, useEffect } from "react";

import "./sessionContainer.scss";

import { BlackTrash, History, Trash, BlackHistory } from "assets/index";

import { getStoredSessions, updateSessionStatus, saveToLocalStorage } from "services/sessionServices";

import { SessionList } from "components/sessionList/SessionList";
import { CustomToast } from "components/customToast/CustomToast";

export const SessionContainer = ({
    selectedTab,
    setSelectedTab,
    sessionView,
    sessions,
    sessionDeleted,
    openModal,
}) => {


    const [recents, setRecents] = useState({});
    // Fetching stored sessions from localStorage with error handling
    useEffect(() => {
        try {
            const storedSessions = getStoredSessions();
            setRecents(storedSessions);
        } catch (error) {
            console.error("Failed to fetch sessions from localStorage", error);
            CustomToast("Error fetching sessions. Please try again.", "error");
        }
    }, [sessions]);

    // Saving the session data to localStorage with error handling
    const handleSaveToLocalStorage = (updatedSessions) => {
        try {
            saveToLocalStorage(updatedSessions);
            setRecents(updatedSessions);
        } catch (error) {
            console.error("Failed to save sessions to localStorage", error);
            CustomToast("Error saving sessions. Please try again.", "error");
        }
    };

    // Handling deletion from the recent tab with error handling
    const deleteSessionFromRecent = (dates) => {
        try {
            // Ensure dates is an array, even if a single date is passed
            const sessionDates = Array.isArray(dates) ? dates : [dates];
            const updatedSessions = { ...recents };

            sessionView("")
            sessionDeleted()

            sessionDates.forEach((date) => {
                if (updatedSessions[date]) {
                    updateSessionStatus(updatedSessions, date, false); // Move to trash
                }
            });

            handleSaveToLocalStorage(updatedSessions);

            CustomToast(
                `${sessionDates.length > 1 ? `${sessionDates.length} Sessions` : "Session"} deleted successfully`,
                "success"
            );

        } catch (error) {
            console.error("Failed to delete sessions from recent", error);
            CustomToast("Error deleting session(s). Please try again.", "error");
        }
    };

    // Handling permanent deletion (confirmation through modal) with error handling
    const deleteSessionFromStorage = (dates) => {
        try {
            // Ensure dates is an array, even if a single date is passed
            const sessionDates = Array.isArray(dates) ? dates : [dates];
            openModal("delete", sessionDates); // Handle multiple session dates in modal

        } catch (error) {
            console.error("Failed to open delete modal", error);
            CustomToast("Error opening delete confirmation. Please try again.", "error");
        }
    };

    // Decide which delete handler to call based on selectedTab
    const handleOnDelete = (date) => {
        try {
            if (selectedTab === "recent") {
                deleteSessionFromRecent(date);
            } else {
                deleteSessionFromStorage(date);
            }
        } catch (error) {
            console.error("Failed to handle session deletion", error);
            CustomToast("Error handling session deletion. Please try again.", "error");
        }
    };

    // Restoring a session from trash with error handling
    const restoreSession = (date) => {
        try {
            const updatedSessions = { ...recents };
            if (updatedSessions[date]) {
                updateSessionStatus(updatedSessions, date, true);
                handleSaveToLocalStorage(updatedSessions);
                CustomToast("Session restored successfully", "success");
            }
        } catch (error) {
            console.error("Failed to restore session", error);
            CustomToast("Error restoring session. Please try again.", "error");
        }
    };

    return (
        <div className="sessionContainer">
            <div className="sessionTopContainer">
                <div className="tabSwitchContainer">
                    <button
                        className={selectedTab === "recent" ? "active" : ""}
                        onClick={() => setSelectedTab("recent")}
                    >
                        <img
                            src={selectedTab === "recent" ? History : BlackHistory}
                            alt="History Icon"
                        />
                        Recent Sessions
                    </button>
                    <button
                        className={selectedTab === "trash" ? "active" : ""}
                        onClick={() => setSelectedTab("trash")}
                    >
                        <img
                            src={selectedTab === "trash" ? Trash : BlackTrash}
                            alt="Trash Icon"
                        />
                        Trash
                    </button>
                </div>
            </div>
            <SessionList
                sessions={recents}
                selectedTab={selectedTab}
                onDelete={handleOnDelete}
                onRestore={selectedTab === "trash" ? restoreSession : null}
                showRestore={selectedTab === "trash"}
                showSession={sessionView}
            />
        </div>
    );
}