import "./actionContainer.scss";

import {
    NewSession,
    StopIcon,
    PauseIcon,
    ResumeIcon,
    DisabledDocument,
    ExpandIcon,
    CollapseIcon,
    ShareIcon,
    EmailSend,
    Document,
    PrinterIcon,
    MicIcon,
    DisabledCollapseIcon,
    DisabledNewSession,
    DisabledEmailSend,
    DisabledShareIcon,
    DisabledPrinterIcon,
} from "assets/index";

import { CustomToast } from "components/customToast/CustomToast";

export const ActionContainer = ({
    selected,
    isRecording,
    isPaused,
    dictationText = "",
    isExpanded,
    handleRecording,
    handlePause,
    handleResume,
    handleStop,
    openModal,
    toggleExpand,
    handleFetchSoapNote,
    printing,
    isSoapNoteLoader,
    isDisable,
}) => {
    const handleModal = (type) => {
        openModal(type);
    };

    // Handle note uploading
    const handleUploading = () => {
        console.log("Uploaded");
        CustomToast("Uploading functionality will be available soon", "info");
    };

    return (
        <div className="actionContainer">
            <div className="actionContainerSub">
                <button
                    className={`action ${isDisable ? "disabled" : ""}`}
                    disabled={isDisable}
                    onClick={() => handleModal("newSession")}
                >
                    {isDisable ? (
                        <img src={DisabledNewSession} alt="Disabled Plus Icon" />
                    ) : (
                        <img src={NewSession} alt="Plus Icon" />
                    )}
                </button>

                {selected !== "notes" && (
                    <>
                        {isRecording ? (
                            <div className={`recording`}>
                                {isPaused ? (
                                    <button onClick={handleResume} className="action">
                                        <img src={ResumeIcon} alt="Resume Icon" />
                                    </button>
                                ) : (
                                    <button onClick={handlePause} className="action">
                                        <img src={PauseIcon} alt="Pause Icon" />
                                    </button>
                                )}
                                <button onClick={handleStop} className="action">
                                    <img src={StopIcon} alt="Stop Icon" />
                                </button>
                            </div>
                        ) : (
                            <button
                                className={`micContainer ${isRecording ? "recording" : ""}`}
                                onClick={handleRecording}
                            >
                                <img src={MicIcon} alt="Mute Icon" />
                            </button>
                        )}
                        {!isRecording && (
                            <button
                                className={`action ${dictationText?.length === 0 || isDisable ? "disabled" : ""
                                    }`}
                                disabled={dictationText?.length === 0 || isDisable}
                                onClick={handleFetchSoapNote}
                            >
                                {dictationText.length === 0 || isDisable ? (
                                    <img src={DisabledDocument} alt="Disabled Document Icon" />
                                ) : (
                                    <img src={Document} alt="Document Icon" />
                                )}
                            </button>
                        )}
                    </>
                )}

                {selected === "notes" && (
                    <>
                        <button className="action" onClick={() => handleUploading()}>
                            {isDisable ? (
                                <img src={DisabledShareIcon} alt="Disabled Share Icon" />
                            ) : (
                                <img src={ShareIcon} alt="Share Icon" />
                            )}
                        </button>
                        <button
                            className="action"
                            onClick={() => handleModal("send email")}
                        >
                            {isDisable ? (
                                <img src={DisabledEmailSend} alt="Disabled Email Icon" />
                            ) : (
                                <img src={EmailSend} alt="Email Icon" />
                            )}

                        </button>
                        <button className={`action`} onClick={() => printing()}>
                            {isDisable ? (
                                <img src={DisabledPrinterIcon} alt="Disabled Printer Icon" />
                            ) : (
                                <img src={PrinterIcon} alt="Print " />
                            )}
                        </button>
                    </>
                )}

                <button
                    className={`action ${isDisable ? "disabled" : ""}`}
                    disabled={isDisable}
                    onClick={toggleExpand}
                >
                    <img
                        src={
                            isDisable
                                ? DisabledCollapseIcon
                                : isExpanded
                                    ? CollapseIcon
                                    : ExpandIcon
                        }
                        alt={isExpanded ? "Collapse Icon" : "Expand Icon"}
                    />
                </button>
            </div>
        </div>
    );
};
