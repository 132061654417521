import './homePage.scss';
import { useState, useEffect } from 'react';
import { Toaster } from 'react-hot-toast';

import { getStoredSessions, saveToLocalStorage } from 'services/sessionServices';

import { RecordingContainer } from 'components/recordingContainer/RecordingContainer';
import { Navbar } from 'components/navbar/Navbar';
import { SessionContainer } from 'components/sessionContainer/SessionContainer';
import { NewSessionModal } from 'components/newSessionModal/NewSessionModal';

function HomePage() {
    const [isExpanded, setIsExpanded] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalType, setModalType] = useState("");
    const [templateName, setTemplateName] = useState("Default Template");
    const [selectedTab, setSelectedTab] = useState("recent");
    const [sessionName, setSessionName] = useState("Session");
    const [sessionView, setSessionView] = useState("");
    const [sessions, setSessions] = useState({});
    const [sessionKeyToDelete, setSessionKeyToDelete] = useState("");
    const [updated, setUpdated] = useState(false);
    const [startRecord, setStartRecord] = useState(false);

    useEffect(() => {
        const storedSessions = getStoredSessions();
        setSessions(storedSessions);
    }, [sessionView]);

    useEffect(() => {
        if (sessionView && sessionView?.patientName) {
            setSessionName(sessionView.patientName);
        }
    }, [sessionView]);

    useEffect(() => {
        if (updated) {
            const storedSessions = getStoredSessions();
            setSessions(storedSessions);
            setUpdated(false);
        }
    }, [updated]);

    const openModal = (type, sessionKey = "") => {
        setModalType(type);
        setSessionKeyToDelete(sessionKey);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setModalType("");
        setSessionKeyToDelete("");
    };

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    const handleTemplate = (template) => {
        setTemplateName(template);
    };

    const handleSessionView = (session) => {
        setSessionView(session);
    };

    const handleSessionCreate = (updatedSessions, newSessionId) => {
        const newSession = updatedSessions[newSessionId];
        setSessionName(newSession.patientName);
        setSessions(updatedSessions);
        saveToLocalStorage(updatedSessions);
        setSessionView(newSession);
    };

    const handleDeleteConfirm = () => {
        const updatedSessions = { ...sessions };
        delete updatedSessions[sessionKeyToDelete];
        setSessions(updatedSessions);
        setSessionView("");
        setSessionName("Session");
        closeModal();
    };

    const hanldeNameNull = () => {
        setSessionName("Session")
    }

    const handleRecordingStart = () => {
        setStartRecord(true);
    }



    return (
        <div className={`mainContainer ${isExpanded ? "expanded" : "collapsed"}`}>
            <Navbar hide={isExpanded} />
            <SessionContainer
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
                sessionView={handleSessionView}
                sessions={sessions}
                openModal={openModal}
                sessionDeleted={hanldeNameNull}
            />
            <Toaster position="top-center" />
            <RecordingContainer
                setExpanded={toggleExpand}
                openModal={openModal}
                isModalOpen={isModalOpen}
                modalType={modalType}
                setUpdated={setUpdated}
                newSessionName={sessionName}
                templateName={templateName}
                sessionView={sessionView}
                setSessionView={setSessionView}
                isExpanded={isExpanded}
                setIsExpanded={setIsExpanded}
                startRecord={startRecord}
                setStartRecord={setStartRecord}
            />

            {isModalOpen && (
                <NewSessionModal
                    onClose={closeModal}
                    onSessionCreate={handleSessionCreate}
                    type={modalType}
                    onTemplateSelect={handleTemplate}
                    sessionKeyToDelete={sessionKeyToDelete}
                    onDeleteConfirm={handleDeleteConfirm}
                    handleRecordingStart={handleRecordingStart}
                />
            )}
        </div>
    );
}

export default HomePage;