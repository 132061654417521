import { toast } from "react-hot-toast";
import { FaCheckCircle, FaExclamationCircle, FaInfoCircle } from 'react-icons/fa';

const icons = {
    success: <FaCheckCircle color="#039855" />,
    error: <FaExclamationCircle color="#B91C1C" />,
    info: <FaInfoCircle color="#2563EB" />,
};

export const CustomToast = (message, type = "info") => {
    toast(message, {
        icon: icons[type] || icons.info,
        style: {
            borderRadius: "8px",
            background: "var(--toast-bg)",
            color: "#fff"
        },
        duration: 1000,
    });
};

export const ToastWithPromise = (promise, settings) => {
    return toast.promise(
      promise,
      {
        loading: 'Stopping recording and transcribing audio...',
        success: 'Audio transcribed successfully!',
        error: 'Error during recording or transcription.',
      },
      {
        style: {
          borderRadius: "8px",
          background: "var(--toast-bg)",
          color: "#fff",
        },
      }
    );
  };
  