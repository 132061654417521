import { useState, useEffect, useRef } from "react";
import "./recordingContainer.scss";

import {
    PlusIcon,
    ClipBoard,
    EditIcon,
    SaveIcon,
    CancelIcon,
    Menu,
} from "assets/index";

import { formatTime, timeToSeconds } from "services/timerServices";
import { fetchSoapNote } from "services/soapServices";
import { MainProcessor } from "services/mainProcessor";
import { updateSessionDetails } from "services/sessionServices";
import { printSOAPNote } from "services/printingService";

import { NoteContainer } from "components/noteContainer/NoteContainer";
import { Timer } from "components/timer/Timer";
import { CustomToast, ToastWithPromise } from "components/customToast/CustomToast.js";
import { ActionContainer } from "components/actionContainer/ActionContainer.js";

export const RecordingContainer = ({
    setExpanded,
    openModal,
    templateName,
    newSessionName,
    sessionView,
    isExpanded,
    setIsExpanded,
    setSessionView,
    setUpdated,
    startRecord,
    setStartRecord,
}) => {

    const [selected, setSelected] = useState("dictation");
    const [isRecording, setIsRecording] = useState(false);
    const [isPaused, setIsPaused] = useState(false);
    const [time, setTime] = useState(0);
    const [isContainerExpanded, setIsContainerExpanded] = useState(false);
    const [dictationText, setDictationText] = useState(sessionView?.dictation || "");
    const [isSessionEditable, setIsSessionEditable] = useState(false);
    const [sessionName, setSessionName] = useState(newSessionName);
    const [transcriptText, setTranscriptText] = useState("");
    const [soapNote, setSoapNote] = useState({});
    const processorRef = useRef(null);
    const [isFullAudioTranscribed, setIsFullAudioTranscribed] = useState(false);
    const [previousSessionName, setPreviousSessionName] = useState(sessionName);
    const [isDictaionLoader, setDictationIsLoader] = useState(false);
    const [isSoapNoteLoader, setIsSoapNoteLoader] = useState(false);
    const [isDictationChanged, setIsDictationChanged] = useState(false);
    const [isDisable, setIsDisable] = useState(false)

    useEffect(() => {
        setSessionName(newSessionName);
        setDictationText(sessionView?.dictation || "")
    }, [newSessionName]);

    
    useEffect(() => {
        setSelected("dictation");
    }, [sessionView.patientName]);

    useEffect(() => {
        if (sessionView?.dictation) {
            setDictationText(sessionView?.dictation || "");
        }
    }, [sessionView]);

    useEffect(() => {
        if (sessionView?.duration) {
            setTime(timeToSeconds(sessionView.duration));
        } else {
            setTime(0);
        }
    }, [sessionView.duration]);

    useEffect(() => {
        let interval = null;

        if (!isRecording && sessionView?.duration) {
            setTime(timeToSeconds(sessionView.duration));
        }

        if (isRecording && !isPaused) {
            interval = setInterval(() => {
                setTime((prevTime) => prevTime + 1);
            }, 1000);
        }

        return () => clearInterval(interval);
    }, [isRecording, isPaused, sessionView.duration]);

    
    function callback(data) {
        if (data && typeof data === "object") {
            const sortedValues = Object.entries(data)
                .sort(([keyA], [keyB]) => Number(keyA) - Number(keyB))
                .map(([key, value]) => value);
            setTranscriptText(sortedValues.join(" "));
        } else {
            console.error("Invalid data received:", data);
        }
    }

    
    const handleRecording = () => {
        setIsDisable(true)
        setTranscriptText("");
        setIsFullAudioTranscribed(false);
        setIsDictationChanged(true);
        
        if (!sessionView || !sessionView.id) {
            openModal("newSession");
            return;
        }
        const newProcessor = new MainProcessor(
            process.env.REACT_APP_WS_URL,
            callback
        );
        processorRef.current = newProcessor;
        setIsPaused(false);
        
        processorRef.current.getMicrophoneAccess()
        .then(() => {
            setIsRecording(true);
            newProcessor.startRecording();
        })
        .catch((err) => {
            console.log(err);
        })

        setIsExpanded(true);
        if (!isExpanded) {
            toggleExpand();
        }

        setTime((prevTime) =>
            sessionView.duration ? timeToSeconds(sessionView.duration) : prevTime
        );
    };

    
    const handlePause = () => {
        processorRef.current.pauseRecording();
        setIsPaused(true);
    };

    
    const handleResume = () => {
        processorRef.current.resumeRecording();
        setIsPaused(false);
    };

    
    const handleStop = () => {
        setDictationIsLoader(true);
        setIsRecording(false);
        setStartRecord(false);
        const formattedDuration = formatTime(time);
        updateSessionDetails(sessionView.id, {
          ...sessionView,
          duration: formattedDuration,
        });
        setSessionView((prevSessionView) => ({
          ...prevSessionView,
          duration: formattedDuration,
        }));
      
        const stopRecordingPromise = processorRef.current.stopRecording((newTranscript) => {
          setDictationText((prev) => {
            const updatedDictation = prev
              ? `${prev} ${newTranscript || transcriptText}`.trim()
              : newTranscript?.trim() || transcriptText;
      
            const updatedSession = {
              ...sessionView,
              duration: formattedDuration,
              dictation: updatedDictation,
            };
      
            setSessionView(updatedSession);
            updateSessionDetails(sessionView.id, updatedSession);
            return updatedDictation;
          });
        });
      
        // Use ToastWithPromise to handle promise resolution and error tracking
        ToastWithPromise(stopRecordingPromise);
      
        // Clean up after stopping the recording
        setIsRecording(false);
        setIsPaused(false);
        processorRef.current = null;
        setDictationIsLoader(false);
      };


  // Toggle expand/collapse state
    const toggleExpand = () => {
        setIsContainerExpanded((prev) => !prev);
        setExpanded((prev) => !prev);
    };
    
    // Edit session name
    const handleEditClick = () => {
        setPreviousSessionName(sessionName);
        setIsSessionEditable(true);
    };

    // Save session name change
    const handleSaveClick = (newName) => {
        if (newName.trim() === "") {
            CustomToast("Session name can't be empty", "info");
            return;
        }
        setSessionName(newName);
        const updatedData = {
            patientName: newName,
        };
        updateSessionDetails(sessionView.id, updatedData);
        setIsSessionEditable(false);
        setUpdated(true);
    };
    
    // Cancel session name editing
    const handleCancelClick = () => {
        setSessionName(previousSessionName);
        setIsSessionEditable(false);
    };
    
    const handleFetchSoapNote = () => {
        setSelected("notes");
        setIsDisable(true)
        
        if (isDictationChanged) {
            setIsSoapNoteLoader(true);
            setIsExpanded(true);
            if (!isExpanded) {
                toggleExpand();
            }
            fetchSoapNote(dictationText)
            .then((result) => {
                const parsedSoapNote = result?.soapnote;
                setIsSoapNoteLoader(false);
                setSoapNote({
                    Subjective: parsedSoapNote.subjective || "",
                    Objective: parsedSoapNote.objective || "",
                    Assessment: parsedSoapNote.assessment || "",
                    Plan: parsedSoapNote.plan || "",
                });
                    updateSessionDetails(sessionView.id, {
                        ...sessionView,
                        SOAP: {
                            Subjective: parsedSoapNote.subjective || "",
                            Objective: parsedSoapNote.objective || "",
                            Assessment: parsedSoapNote.assessment || "",
                            Plan: parsedSoapNote.plan || "",
                        },
                    });
                    setSessionView({
                        ...sessionView,
                        SOAP: {
                            Subjective: parsedSoapNote.subjective || "",
                            Objective: parsedSoapNote.objective || "",
                            Assessment: parsedSoapNote.assessment || "",
                            Plan: parsedSoapNote.plan || "",
                        },
                    });
                    setIsFullAudioTranscribed(true)
                    setIsDictationChanged(false);
                    setUpdated(true);
                    setIsDisable(false)
                })
                .catch((error) => {
                    console.error("Error fetching SOAP note:", error);
                    setIsSoapNoteLoader(false);
                    setIsDisable(false)
                });
                setIsSessionEditable(false);
            }
    };
    const handlePrinting = () => {
        try {
            console.log("In recordin container", soapNote);
            const result = printSOAPNote(soapNote, templateName)
            { result != null && CustomToast("Printing SOAP Note") }
        } catch (error) {
            CustomToast("Error while printing SOAP Note", "Error")
        }
    }
    useEffect(() => {
        if (sessionView && sessionView.patientName) {
            setSessionName(sessionView.patientName);
        }
    }, [sessionView]);

    useEffect(() => {
        if (startRecord) {
            handleRecording();
        }
    }, [startRecord]);

    return (
        <div className="recordingContainer">
            <div className="recordingTopContainer">
                <div className="recordingTopSubContainer">
                    <button
                        className={`option ${selected === "dictation" ? "selectedOption" : ""
                            }`}
                        onClick={() => setSelected("dictation")}
                    >
                        <img src={PlusIcon} alt="Plus Icon" />
                        Dictation
                    </button>

                    <button
                        className={`option ${selected === "notes" ? "selectedOption" : ""}`}
                        onClick={() => {
                            setSelected("notes");
                            handleFetchSoapNote();
                        }}
                        disabled={dictationText.length === 0 || isRecording}
                    >
                        <img src={ClipBoard} alt="Clipboard Icon" />
                        AI Summary
                    </button>
                </div>
                <div className="sessionNameAndTimer">
                    {isSessionEditable ? (
                        <div className="inputContainer">
                            <input
                                type="text"
                                value={sessionName}
                                onChange={(e) => setSessionName(e.target.value)}
                                maxLength={20}
                                autoFocus
                            />
                            <div className="buttonContainer">
                                <button onClick={() => handleSaveClick(sessionName)}>
                                    <img src={SaveIcon} alt="Save" />
                                </button>
                                <button onClick={handleCancelClick}>
                                    <img src={CancelIcon} alt="Cancel" />
                                </button>
                            </div>
                        </div>
                    ) : (
                        <div className="sessionName">
                            <p>{selected === "dictation" ? sessionName : templateName}</p>
                            {selected === "dictation" && (
                                <button className="editButton" onClick={handleEditClick}>
                                    <img src={EditIcon} alt="Edit Session Name" />
                                </button>
                            )}
                        </div>
                    )}
                    {!isSessionEditable &&
                        (selected === "dictation" ? (
                            <Timer
                                isPaused={isPaused}
                                isRecording={isRecording}
                                time={time}
                                sessionView={sessionView}
                            />
                        ) : (
                            <button
                                className="menu"
                                onClick={() => openModal("choose template")}
                            >
                                <img src={Menu} alt="Menu Icon" />
                            </button>
                        ))}
                </div>
            </div>

            <div className="recordingBottomContainer">
                <NoteContainer
                    isRecording={isRecording}
                    isPaused={isPaused}
                    time={time}
                    dictationText={dictationText}
                    setDictationText={setDictationText}
                    selectedTab={selected}
                    sessionView={sessionView}
                    setSessionView={setSessionView}
                    transcriptText={transcriptText}
                    setTranscriptText={setTranscriptText}
                    isFullAudioTranscribed={isFullAudioTranscribed}
                    setIsFullAudioTranscribed={setIsFullAudioTranscribed}
                    setSoapNote={setSoapNote}
                    soapNote={soapNote}
                    isDictaionLoader={isDictaionLoader}
                    isSoapNoteLoader={isSoapNoteLoader}
                    isExpanded={isExpanded}
                    setIsDictationChanged={setIsDictationChanged}
                />
            </div>
            <div className="actionContainerMain">
                <ActionContainer
                    isRecording={isRecording}
                    isPaused={isPaused}
                    dictationText={dictationText}
                    isExpanded={isContainerExpanded}
                    handleRecording={handleRecording}
                    handlePause={handlePause}
                    handleResume={handleResume}
                    handleStop={handleStop}
                    openModal={openModal}
                    toggleExpand={toggleExpand}
                    selected={selected}
                    handleFetchSoapNote={handleFetchSoapNote}
                    setSelected={setSelected}
                    printing={handlePrinting}
                    isSoapNoteLoader={isSoapNoteLoader}
                    isFullAudioTranscribed={isFullAudioTranscribed}
                    isDisable={isDisable}
                />
            </div>
        </div>
    )
};