import React from "react";
import "./soapNoteLoader.scss";

function SoapNoteLoader(
    isExpanded
) {
    return (
        <div className="loaderContainer">
            <div className="loaderBox">
                <div className="loaderHead">
                    <div className="loaderHeadLargeComponent"></div>
                </div>
                <div className="loaderBody"></div>
            </div>

            <div className="loaderBox">
                <div className="loaderHead">
                    <div className="loaderHeadLargeComponent"></div>
                </div>
                <div className="loaderBody"></div>
            </div>

            <div className="loaderBox">
                <div className="loaderHead">
                    <div className="loaderHeadLargeComponent"></div>
                </div>
                <div className="loaderBody"></div>
            </div>

            <div className="loaderBox">
                <div className="loaderHead">
                    <div className="loaderHeadLargeComponent"></div>
                </div>
                <div className="loaderBody"></div>
            </div>

            <div className="loaderBox">
                <div className="loaderHead">
                    <div className="loaderHeadLargeComponent"></div>
                </div>
                <div className="loaderBody"></div>
            </div>
        </div>
    );
}

export default SoapNoteLoader;
