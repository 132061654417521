import jsPDF from "jspdf";

export const printSOAPNote = (soapNote, template) => {
  const doc = new jsPDF("p", "mm", "a4");

  doc.setFontSize(18);
  doc.text(template, 105, 20, { align: "center" });

  let yPosition = 30;

  doc.setFontSize(14);
  Object.entries(soapNote).forEach(([key, value]) => {

    doc.text(`${key}:`, 10, yPosition);
    yPosition += 8;

    doc.setFontSize(12);
    const items = Array.isArray(value) ? value : [value];
    items.forEach(item => {
      if (yPosition > 280) {
        doc.addPage();
        yPosition = 20;
      }

      doc.text(`- ${item}`, 15, yPosition);
      yPosition += 6;
    });

    yPosition += 10;
  });

  const pdfFileName = "SOAP_Note.pdf";
  doc.save(pdfFileName);
};