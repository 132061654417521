import { useState, useEffect, useRef } from "react";
import "./noteContainer.scss";
import SoapNoteLoader from "components/loader/SoapNoteLoader";

import { EditIcon } from "assets/index";
import { updateSessionDetails } from "services/sessionServices";
import { Loader } from "components/loader/Loader";

export const NoteContainer = ({
    isRecording,
    time,
    dictationText,
    setDictationText,
    selectedTab,
    sessionView,
    transcriptText,
    isFullAudioTranscribed,
    setIsFullAudioTranscribed,
    soapNote,
    setSoapNote,
    isDictaionLoader,
    isSoapNoteLoader,
    isExpanded,
    setSessionView,
    setIsDictationChanged
}) => {

    const [editSection, setEditSection] = useState(null);
    const dictationTextareaRef = useRef(null);
    const soapTextareaRef = useRef({});
    const [isEditing, setIsEditing] = useState(false);

    useEffect(() => {
        if (sessionView) {
            if (selectedTab === "notes") {
                setSoapNote({
                    Subjective: sessionView?.SOAP?.Subjective ?? [],
                    Objective: sessionView?.SOAP?.Objective ?? [],
                    Assessment: sessionView?.SOAP?.Assessment ?? [],
                    Plan: sessionView?.SOAP?.Plan ?? [],
                });
            } else if (selectedTab === "dictation") {
                setDictationText(sessionView.dictation || "");
            }
        }
    }, [selectedTab, sessionView, setDictationText]);

    useEffect(() => {
        if (editSection === "dictation" && dictationTextareaRef.current) {
            dictationTextareaRef.current.focus();
        } else if (editSection !== "dictation" && soapTextareaRef.current[editSection]) {
            const textarea = soapTextareaRef.current[editSection];
            textarea.focus();
            textarea.setSelectionRange(textarea.value.length, textarea.value.length);
            textarea.scrollTop = textarea.scrollHeight;
        }
    }, [editSection]);

    useEffect(() => {
        if (!isRecording && dictationText) {
            localStorage.setItem("dictationText", dictationText);
        }
    }, [isRecording, dictationText]);

    useEffect(() => {
        if (dictationTextareaRef.current && isRecording) {
            dictationTextareaRef.current.scrollTop = dictationTextareaRef.current.scrollHeight;
        }
    }, [dictationText, transcriptText, isRecording, isFullAudioTranscribed]);

    const handleDictationChange = (e) => {
        setDictationText(e.target.value);
        setSessionView((prev) => ({
            ...prev,
            dictation: e.target.value
        }));
        setIsDictationChanged(true);
    };

    const handleSoapNoteChange = (key, value) => {
        const newValue = value.split("\n");
        setSoapNote((prev) => ({
            ...prev,
            [key]: newValue,
        }));

        setSessionView((prevSessionView) => ({
            ...prevSessionView,
            SOAP: {
                ...prevSessionView.SOAP,
                [key]: newValue
            }
        }));
    };

    const handleEditClick = (key) => {
        setEditSection(key);
    };

    const handleBlur = () => {
        const updatedData =
            selectedTab === "dictation"
                ? { dictation: dictationText }
                : { SOAP: soapNote };

        if (sessionView) {
            updateSessionDetails(sessionView?.id, updatedData);
        }
        setIsEditing(false);
        setEditSection(null);
    };

    const isSoapNoteEmpty = () => {
        return (
            !soapNote ||
            Object.values(soapNote).every((value) => !value?.length)
        );
    };

    return (
        <div className="noteContainer">
            <div className="noteHeader" />
            <div
                className="notes"
                style={editSection ? { borderColor: "var(--title-text)" } : {}}
            >
                {selectedTab === "dictation" ? (
                    isDictaionLoader ? (
                        isExpanded ? (
                            <>
                                <Loader isExpanded={isExpanded} />
                                <Loader isExpanded={isExpanded} />
                                <Loader isExpanded={isExpanded} />
                                <Loader isExpanded={isExpanded} />
                            </>
                        ) : (
                            <Loader isExpanded={isExpanded} />
                        )
                    ) : (
                        <>
                            <textarea
                                ref={dictationTextareaRef}
                                placeholder={isRecording ? "Dictating..." : "Start Dictation" }
                                value={
                                    isEditing
                                        ? sessionView.dictation || dictationText
                                        : isRecording
                                            ? dictationText + "" + transcriptText
                                            : isFullAudioTranscribed
                                                ? dictationText
                                                : dictationText + "" + transcriptText
                                }
                                onChange={handleDictationChange}
                                disabled={isRecording || editSection !== "dictation"}
                                onBlur={handleBlur}
                            />
                            {time > 0 && !isRecording && editSection !== "dictation" && (
                                <button
                                    className="editButton"
                                    onClick={() => {
                                        setEditSection("dictation");
                                        setIsEditing(true);
                                    }}
                                >
                                    <img src={EditIcon} alt="Edit Content" />
                                </button>
                            )}
                        </>
                    )
                ) : isSoapNoteLoader ? (
                    isExpanded ? (
                        <SoapNoteLoader isExpanded={isExpanded} />
                    ) : (
                        <SoapNoteLoader isExpanded={isExpanded} />
                    )
                ) : isSoapNoteEmpty() ? (
                    <div className="noContentMessage">
                        No available contents to generate SOAP note
                    </div>
                ) : (
                    <div className="soapContent">
                        {Object.entries(soapNote).map(([key, value]) => (
                            <div key={key} className="soapSection" id="soapSection">
                                <div className="sectionHeader">
                                    <h2 className="heading">{key}:</h2>
                                </div>
                                {editSection === key ? (
                                    <textarea
                                        ref={(el) => (soapTextareaRef.current[key] = el)}
                                        value={Array.isArray(value) ? value.join("\n") : value}
                                        onChange={(e) => handleSoapNoteChange(key, e.target.value)}
                                        onBlur={handleBlur}
                                        className="textarea"
                                    />
                                ) : (
                                    <div className="valueDisplay">
                                        <ul className="valueList">
                                            <img
                                                src={EditIcon}
                                                alt="Edit Content"
                                                className="editIcon"
                                                onClick={() => handleEditClick(key)}
                                            />
                                            {(Array.isArray(value) ? value : [value]).map(
                                                (item, index) => (
                                                    <li key={index} className="valueItem">
                                                        {item}
                                                    </li>
                                                )
                                            )}
                                        </ul>
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};