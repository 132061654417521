import ContentLoader from 'react-content-loader';

export const Loader = ({ isExpanded }) => {
    const width = isExpanded ? 334 : 340;
    const height = isExpanded ? 150 : 160;
    const rectWidth = isExpanded ? '20.875rem' : '340px';

    return (
        <ContentLoader
            speed={2}
            width={width}
            height={height}
            viewBox={`0 0 ${width} ${height}`}
            backgroundColor="#f2f2f2"
            foregroundColor="#EAECF0"
        >
            <rect x="0" y="0" rx="5" ry="5" width={rectWidth} height="0.9375rem" />
            <rect x="0" y="24" rx="5" ry="5" width={rectWidth} height="0.9375rem" />
            <rect x="0" y="51" rx="5" ry="5" width={rectWidth} height="0.9375rem" />
            <rect x="0" y="78" rx="5" ry="5" width={rectWidth} height="0.9375rem" />
            <rect x="0" y="105" rx="5" ry="5" width={isExpanded ? '15rem' : '8.6875rem'} height="0.9375rem" />
        </ContentLoader>
    );
}