export const ChooseTemplateContent = ({ selectedTemplate, handleTemplateChange }) => {
    return (
        <div className="templateOptions">
            <label>
                <input
                    type="radio"
                    value="Default Template"
                    checked={selectedTemplate === "Default Template"}
                    onChange={handleTemplateChange}
                    defaultChecked={
                        selectedTemplate === "" || selectedTemplate === "Default Template"
                    }
                />
                Default Template
            </label>
            <label>
                <input
                    type="radio"
                    value="Pediatrics"
                    checked={selectedTemplate === "Pediatrics"}
                    onChange={handleTemplateChange}
                />
                Pediatrics
            </label>
            <label>
                <input
                    type="radio"
                    value="Emergency"
                    checked={selectedTemplate === "Emergency"}
                    onChange={handleTemplateChange}
                />
                Emergency
            </label>
        </div>
    );
}