import { CloseIcon } from "assets/index";

export const EmailContent = ({
    emails,
    currentEmail,
    handleEmailInput,
    handleEmailRemove,
}) => {
    return (
        <div className="emailContainer">
            <p className="subHeading">Email</p>
            <div className="emailWrapper">
                {emails.map((email, index) => (
                    <div key={index} className="emailTag">
                        {email}
                        <img
                            src={CloseIcon}
                            onClick={() => handleEmailRemove(index)}
                            alt="Close"
                            className="removeEmail"
                        />
                    </div>
                ))}
            </div>
            <div className="inputEmail">
                <input
                    type="email"
                    placeholder="Enter email"
                    value={currentEmail}
                    className="emailArea"
                    onChange={handleEmailInput}
                    required
                />
            </div>
        </div>
    );
}