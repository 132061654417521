export const NewSessionContent = ({
    patientName,
    setPatientName,
    phn,
    setPHN,
    error,
}) => {

    const handlePHNChange = (e) => {
        const value = e.target.value;
        if (value.length <= 10) {
            setPHN(value);
        }
    };

    return (
        <>
            <div className="inputContainer">
                <label>Patient Name</label>
                <input
                    type="text"
                    id="patientName"
                    value={patientName}
                    required
                    maxLength={20}
                    onChange={(e) => setPatientName(e.target.value)}
                />
            </div>
            <div className="inputContainer">
                <label>PHN</label>
                <input
                    type="number"
                    id="phn"
                    value={phn}
                    onChange={handlePHNChange}
                />
            </div>
            {error && <p className="errorMessage">{error}</p>}
        </>
    );
}