export const fetchSoapNote = async (dictationText) => {
  const apiUrl = process.env.REACT_APP_SOAPNOTE_URL
    ? process.env.REACT_APP_SOAPNOTE_URL
    : `${window.location.protocol}//${window.location.hostname}/api/soapnote`;

  if (!apiUrl) {
    console.error("SOAP note API URL is not defined.");
    return;
  }

  try {
    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ conversation: dictationText }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.error("Error fetching SOAP note:", error);
  }
};
