import { useState, useRef, useEffect } from "react";
import "./sessionList.scss";

import { BlackTrash, EmailIcon, InProgress, Pending, RestoreIcon, SearchIcon, TickIcon } from "assets/index";
import { filterSessionsByTab, formatDate, searchSessions } from "services/sessionServices";

export const SessionList = ({
    sessions,
    selectedTab,
    onDelete,
    onRestore,
    showRestore,
    showSession,
}) => {
    const [selectedItems, setSelectedItems] = useState({});
    const listRefs = useRef([]);
    const [query, setQuery] = useState("");

    // Filter sessions by the selected tab
    const filteredSessions = filterSessionsByTab(sessions, selectedTab);

    // Apply search filter
    const searchedSessions = searchSessions(filteredSessions, query);

    useEffect(() => {
        listRefs.current.forEach((sessionItem, sessionIndex) => {
            if (sessionItem) {
                sessionItem.style.setProperty("--index", sessionIndex + 1);
            }
        });
    }, [searchedSessions]);

    useEffect(() => {
        const allSelected = Object.keys(searchedSessions).every(
            (sessionDate) => selectedItems[sessionDate]
        );
        const noneSelected = Object.keys(searchedSessions).length === 0;
        setAllSelected(allSelected);
        setNoneSelected(noneSelected);
    }, [selectedItems, searchedSessions]);

    // Handle individual checkbox change
    const handleCheckboxChange = (sessionDate) => {
        setSelectedItems({
            ...selectedItems,
            [sessionDate]: !selectedItems[sessionDate], // Direct update using spread operator
        });
    };

    // Handle clearing all selected sessions
    const handleClearAll = () => {
        const selectedDates = Object.keys(selectedItems).filter(
            (sessionDate) => selectedItems[sessionDate]
        );
        if (selectedDates.length > 0) {
            onDelete(selectedDates); // Pass array of selected dates
            setSelectedItems({});
        }
    };

    // Handle select all sessions checkbox
    const handleSelectAllChange = () => {
        const allSelected = Object.keys(searchedSessions).every(
            (sessionDate) => selectedItems[sessionDate]
        );
        const newSelection = Object.keys(searchedSessions).reduce(
            (acc, sessionDate) => ({
                ...acc,
                [sessionDate]: !allSelected,
            }),
            {}
        );
        setSelectedItems(newSelection);
    };

    // Handle deleting a single session
    const handleDeleteClick = (sessionDate) => {
        onDelete([sessionDate]);
        setSelectedItems((prevSelected) => {
            const updated = { ...prevSelected };
            delete updated[sessionDate];
            return updated;
        });
    };

    // Handle restoring a session
    const handleRestoreClick = (sessionDate) => {
        onRestore(sessionDate);
        setSelectedItems((prevSelected) => {
            const updated = { ...prevSelected };
            delete updated[sessionDate];
            return updated;
        });
    };

    // Handle showing the session details when clicking
    const handleSessionPopulation = (event, session) => {
        const isCheckboxClicked = event.target.tagName === "INPUT";
        const isButtonClicked = event.target.closest("button");

        if (!isCheckboxClicked && !isButtonClicked) {
            showSession(session);
        }
    };

    const showSelectionControls = Object.keys(searchedSessions).length > 0;
    const [allSelected, setAllSelected] = useState(false);
    const [noneSelected, setNoneSelected] = useState(true);
    const [hide, setHide] = useState(false);

    const sortedSessionDates = Object.keys(searchedSessions).sort(
        (a, b) => b.localeCompare(a)
    );

    return (
        <div className="listContainer">
            {Object.keys(filteredSessions).length > 0 && !hide && (
                <div className="searchBarContainer">
                    <div className="searchInputContainer">
                        <img src={SearchIcon} alt="Search Icon" className="searchIcon" />
                        <input
                            type="text"
                            id="query"
                            value={query}
                            onChange={(e) => setQuery(e.target.value)}
                            placeholder="Search by patient name or PHN"
                        />
                    </div>
                </div>
            )}
            {showSelectionControls && (
                <div className="selectionContainer">
                    <div className="inputContainer">
                        <input
                            type="checkbox"
                            checked={allSelected}
                            onChange={handleSelectAllChange}
                        />
                        <p className="inputLabel">Select All</p>
                    </div>
                    <button onClick={handleClearAll} disabled={noneSelected}>
                        <img src={BlackTrash} alt="Delete selected" />
                    </button>
                </div>
            )}
            <ul>
                {sortedSessionDates.length > 0 ? (
                    sortedSessionDates.map((sessionDate, sessionIndex) => {
                        const session = searchedSessions[sessionDate];
                        return (
                            <li
                                key={sessionIndex}
                                ref={(el) => (listRefs.current[sessionIndex] = el)} // Using index for ref key
                                onClick={(event) =>
                                    handleSessionPopulation(event, session)
                                }
                            >
                                <div className="sessionTextContainer">
                                    <input
                                        type="checkbox"
                                        checked={!!selectedItems[sessionDate]}
                                        onChange={() => handleCheckboxChange(sessionDate)}
                                    />
                                    <div className="sessionCenter">
                                        <div className="sessionTop">
                                            <p className="sessionName">{session?.patientName}</p>
                                            {session?.status && (
                                                <div
                                                    className={
                                                        session?.status && !session?.mail
                                                            ? "indicators"
                                                            : "indicatorGroup"
                                                    }
                                                >
                                                    {session?.status === "completed" && (
                                                        <img src={TickIcon} alt="Completed" />
                                                    )}
                                                    {session?.status === "pending" && (
                                                        <img src={Pending} alt="Pending" />
                                                    )}
                                                    {session?.status === "inProgress" && (
                                                        <img src={InProgress} alt="In Progress" />
                                                    )}
                                                    {session?.status && session?.mail && (
                                                        <span className="separator"></span>
                                                    )}
                                                    {session?.mail && (
                                                        <img src={EmailIcon} alt="Email Sent" />
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                        <div className="sessionBottomContainer">
                                            {session?.phn && (
                                                <>
                                                    <p>{session?.phn}</p>
                                                    <span className="ellipse"></span>
                                                </>
                                            )}
                                            <p>{formatDate(sessionDate)}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="rightBtn">
                                    <button
                                        className="deleteButton"
                                        onClick={() => handleDeleteClick(sessionDate)}
                                    >
                                        <img src={BlackTrash} alt="Delete" />
                                    </button>

                                    {showRestore && (
                                        <button
                                            className="restoreButton"
                                            onClick={() => handleRestoreClick(sessionDate)}
                                        >
                                            <img src={RestoreIcon} alt="Restore" />
                                        </button>
                                    )}
                                </div>
                            </li>
                        );
                    })
                ) : (
                    <div className="nullContent">
                        <p>No sessions found</p>
                    </div>
                )}
            </ul>
        </div>
    );
};