import { useState } from "react";

import "./newSessionModal.scss";

import {
    getStoredSessions,
    handleNewSession,
    saveToLocalStorage,
} from "services/sessionServices";

import { ModalFooter } from "./ModalFooter";
import { ModalHeader } from "./ModalHeader";
import { DeleteContent } from "./DeleteContent";
import { EmailContent } from "./EmailContent";
import { NewSessionContent } from "./NewSessionContent";
import { ChooseTemplateContent } from "./ChooseTemplateContent";
import { CustomToast } from "components/customToast/CustomToast";

export const NewSessionModal = ({
    onClose,
    type,
    onTemplateSelect,
    onSessionCreate,
    onDeleteConfirm,
    sessionKeyToDelete,
    handleRecordingStart
}) => {
    const [selectedTemplate, setSelectedTemplate] = useState("Default Template");
    const [patientName, setPatientName] = useState("");
    const [phn, setPHN] = useState("");
    const [error, setError] = useState("");
    const [emails, setEmails] = useState([]);
    const [currentEmail, setCurrentEmail] = useState("");
    const [actionError, setActionError] = useState("");

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    const handleCreateSession = () => {
        try {
            if (validateFields()) {
                const newSession = handleNewSession(patientName, phn);
                const sessions = getStoredSessions();
                handleRecordingStart()
                sessions[newSession.id] = newSession;
                saveToLocalStorage(sessions);
                onSessionCreate(sessions, newSession.id);
                CustomToast("New session created.", "success");
                onClose();
                handleRecordingStart();
            }
        } catch (error) {
            setActionError("Failed to create session. Please try again.");
            CustomToast("Failed to create session.", "error");
        }
    };

    const validateFields = () => {
        let valid = true;
        let errorMessage = "";

        // Validate patientName
        if (!patientName.trim()) {
            errorMessage = "Patient Name is required.";
            valid = false;
        }

        // Update error state if needed
        if (!valid) {
            setError(errorMessage);
        } else {
            setError("");
        }

        return valid;
    };

    const handleEmailInput = (e) => {
        try {
            const email = e.target.value;
            setCurrentEmail(email);

            if (emailRegex.test(email)) {
                setEmails((prevEmails) => [...prevEmails, email]);
                setCurrentEmail("");
            }
        } catch (error) {
            setActionError("Invalid email format. Please check and try again.");
            CustomToast("Invalid email.", "error");
        }
    };

    const handleEmailRemove = (index) => {
        try {
            setEmails((prevEmails) => prevEmails.filter((_, i) => i !== index));
        } catch (error) {
            setActionError("Failed to remove email. Please try again.");
            CustomToast("Failed to remove email.", "error");
        }
    };

    const handleEmailSend = () => {
        try {
            CustomToast("Mail sent successfully.", "success");
            onClose();
        } catch (error) {
            setActionError("Failed to send email. Please try again.");
            CustomToast("Failed to send email.", "error");
        }
    };

    const handleDeleteSession = () => {
        try {
            const sessions = getStoredSessions();

            const sessionDatesToDelete = Array.isArray(sessionKeyToDelete) ? sessionKeyToDelete : [sessionKeyToDelete];

            sessionDatesToDelete.forEach((date) => {
                if (sessions[date]) {
                    delete sessions[date];
                }
            });

            saveToLocalStorage(sessions);

            CustomToast(
                `${sessionDatesToDelete.length > 1 ? "Sessions" : "Session"} deleted from storage`,
                "success"
            );

            onDeleteConfirm();
            onClose();
        } catch (error) {
            setActionError("Failed to delete session(s). Please try again.");
            CustomToast("Failed to delete session(s).", "error");
        }
    };

    const handleTemplateSelection = (event) => {
        setSelectedTemplate(event.target.value);
    };

    const handleSaveTemplate = () => {
        try {
            if (onTemplateSelect) {
                onTemplateSelect(selectedTemplate);
            }
            onClose();
        } catch (error) {
            setActionError("Failed to save template. Please try again.");
            CustomToast("Failed to save template.", "error");
        }
    };

    const renderModalContent = () => {
        switch (type) {
            case "newSession":
                return (
                    <NewSessionContent
                        patientName={patientName}
                        setPatientName={setPatientName}
                        phn={phn}
                        setPHN={setPHN}
                        error={error}
                    />
                );
            case "send email":
                return (
                    <EmailContent
                        emails={emails}
                        currentEmail={currentEmail}
                        handleEmailInput={handleEmailInput}
                        handleEmailRemove={handleEmailRemove}
                    />
                );
            case "choose template":
                return (
                    <ChooseTemplateContent
                        selectedTemplate={selectedTemplate}
                        handleTemplateChange={handleTemplateSelection}
                    />
                );
            case "delete":
                return <DeleteContent />;
            default:
                return null;
        }
    };

    const modalTitle =
        type === "newSession"
            ? "Record New Session"
            : type === "send email"
                ? "Send Email"
                : type === "choose template"
                    ? "Choose Template"
                    : type === "delete"
                        ? "Confirm Delete"
                        : "";

    const confirmText =
        type === "newSession"
            ? "Record"
            : type === "send email"
                ? "Send"
                : type === "choose template"
                    ? "Save"
                    : type === "delete"
                        ? "Delete"
                        : "";

    return (
        <div className="newSessionModalContainer">
            <div className="modalMain">
                <div className="newSessionModal">
                    <ModalHeader title={modalTitle} onClose={onClose} />
                    <div className="content">{renderModalContent()}</div>
                    {actionError && <div className="error">{actionError}</div>}
                    <ModalFooter
                        onClose={onClose}
                        onConfirm={
                            type === "newSession"
                                ? handleCreateSession
                                : type === "send email"
                                    ? handleEmailSend
                                    : type === "delete"
                                        ? handleDeleteSession
                                        : type === "choose template"
                                            ? handleSaveTemplate
                                            : null
                        }
                        confirmText={confirmText}
                        confirmDisabled={type === "newSession" && !patientName.trim()}
                    />
                </div>
            </div>
        </div>
    );
}