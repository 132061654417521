export const ModalFooter = ({ onClose, onConfirm, confirmText, confirmDisabled }) => {
    return (
        <div className="footer">
            <button className="cancelButton" onClick={onClose}>
                Cancel
            </button>
            <button
                className={`recordButton ${confirmText === "Delete" ? "redButton" : ""
                    }`}
                onClick={onConfirm}
                disabled={confirmDisabled}
            >
                {confirmText}
            </button>
        </div>
    );
}