export const formatTime = (seconds) => {
  const mins = Math.floor(seconds / 60);
  const secs = seconds % 60;
  return `${mins.toString().padStart(2, "0")}:${secs
    .toString()
    .padStart(2, "0")}`;
};

export const createTimerInterval = (isRecording, isPaused, setTime) => {
  let interval = null;
  if (isRecording && !isPaused) {
    interval = setInterval(() => {
      setTime((prevTime) => prevTime + 1);
    }, 1000);
  } else if (!isRecording) {
    clearInterval(interval);
  }
  return interval;
};

export const timeToSeconds = (time) => {
  if (!time || typeof time !== "string" || !time.includes(":")) {
    return 0;
  }

  const [minutes, seconds] = time.split(":").map(Number);
  return minutes * 60 + seconds;
};
