const PROTOCOLS = {
    "https:": "wss:",
    "http:": "ws:",
  };  
  export class MainProcessor {
    constructor(WS_URL, cb) {
      this.cb = cb;
      this.host = this.getAppHost();
  
      this.wsUrl = WS_URL
        ? WS_URL
        : `${PROTOCOLS[this.host.protocol]}//${this.host["host"]}/ws`;
      this.baseURL = `${this.host.protocol}//${this.host["domain"]}/api`;
      this.ws = null;
      this.connectionActive = false;
      this.isServerReady = false;
      this.speechSegments = {};
      this.audioChunks = [];
      this.audioFile = null;
      if (this.ws && this.ws?.readyState === WebSocket.OPEN) {
        this.ws.close();
      }
      this.context = null;
    }
  
    getAppHost() {
      const domain = window.location.hostname;
      const protocol = window.location.protocol;
      const port =
        window.location.port !== ""
          ? window.location.port
          : protocol === "https:"
          ? "443"
          : "80";
      return {
        domain: domain,
        host: `${domain}:${port}`,
        protocol: protocol,
      };
    }
  
    initWSConnection(wsUrl) {
      this.ws = new WebSocket(wsUrl);
      this.ws.binaryType = "blob";
      this.ws.onopen = async (e) => {
        this.connectionActive = true;
      };
      this.ws.onerror = async (e) => {
        this.connectionActive = false;
      };
      this.ws.onmessage = async (e) => {
        const data = JSON.parse(e?.data);
        if ("segments" in data) {
          for (const segment in data.segments) {
            if (data.segments[segment].text !== " Thanks for watching!") {
              this.speechSegments[data.segments[segment].start] =
                data.segments[segment].text;
            }
          }
        } else {
          if (data.message === "SERVER_READY") {
            this.isServerReady = true;
          }
        }
        this.cb(this.speechSegments);
      };
    }
  
    async startRecording() {
      // IF NEEDED WE CAN CLEAR THE EXISTING TRANSCRIBE SPEECH
      // this.speechSegments = {};
      if (!this.ws || this.ws?.readyState !== WebSocket.OPEN) {
        this.initWSConnection(this.wsUrl);
    }
    this.mediaRecorder = null; 
    this.context = new (window?.AudioContext ||
        window?.webkitAudioContext ||
        window?.mozAudioContext ||
        window?.oAudioContext ||
        window?.msAudioContext)();

  
      this.getMicrophoneAccess()
        .then(async (stream) => {
          await this.context.audioWorklet.addModule(
            "/worklets/audioProcessor.js"
          );
          const mediaStream = this.context.createMediaStreamSource(stream);
          const audioProcessor = new AudioWorkletNode(
            this.context,
            "audio-processor"
          );
          this.initializeMediaRecorder(stream);      
          audioProcessor.port.onmessage = (event) => {
            const audioData16kHz = event.data;
            
            if (this.connectionActive) {
              if (this.ws && this.ws.readyState === WebSocket.OPEN) {
                this.ws.send(audioData16kHz);
              }
            }
          };
          mediaStream.connect(audioProcessor);
          audioProcessor.connect(this.context.destination);
        })
        .catch((error) => {
          console.error("Error capturing audio:", error.name);
        });
    }

    async getMicrophoneAccess() {
      try {
        return await navigator.mediaDevices.getUserMedia({ audio: true, video: false });
      } catch (error) {
        throw new Error("Failed to get microphone access");
      }
    }
    
    checkWebSocketConnection() {
      if (!this.ws || this.ws.readyState !== WebSocket.OPEN) {
        this.initWSConnection(this.wsUrl);
      }
    }
    
    getUserMedia() {
      return navigator.mediaDevices.getUserMedia({ audio: true, video: false })
        .catch(error => {
          if (error.name === "NotAllowedError") {
            console.error("Permission denied: Please allow microphone access.");
          }
          throw error; 
        });
    }
    
    async setupAudioWorklet() {
      try {
        await this.context.audioWorklet.addModule("/worklets/audioProcessor.js");
      } catch (error) {
        console.error("Error loading AudioWorklet module:", error);
        throw error;  
      }
    }
    
    initializeMediaRecorder(stream) {
      this.mediaOption = { mimeType: 'audio/webm', audioBitsPerSecond: 64000 }; 

      this.mediaRecorder = new MediaRecorder(stream,this.mediaOption);
      this.mediaRecorder.start();
    
      this.mediaRecorder.ondataavailable = (event) => {
        this.audioChunks.push(event.data);
      };
    }
    
    pauseRecording() {
      if (this.context && this.context.state !== "closed") {
        this.context.suspend().then(() => {
          this.mediaRecorder.pause();
        });
      }
    }
  
    resumeRecording() {
      if (this.context && this.context.state !== "closed") {
        this.context.resume().then(() => {
          this.mediaRecorder.resume();
        });
      }
    }
  
    stopRecording(setTranscriptText) {
      return new Promise((resolve, reject) => {
        if (this.context && this.context.state !== "closed") {
          this.context
            .close()
            .then(() => {
              this.mediaRecorder.stop();
              this.mediaRecorder.onstop = () => {
                try {
                  this.processRecording();
                  this.transcribeFullAudio(setTranscriptText)
                    .then(() => {
                      if (this.ws?.readyState === WebSocket.OPEN) {
                        this.ws.close();
                      }
                      resolve("Recording stopped and transcribed successfully.");
                    })
                    .catch((error) => {
                      reject(new Error(`Transcription failed: ${error.message}`));
                    });
                } catch (error) {
                  reject(new Error(`Processing recording failed: ${error.message}`));
                }
              };
            })
            .catch((error) => {
              reject(new Error(`Closing context failed: ${error.message}`));
            });
        } else {
          reject(new Error("No active context or context already closed."));
        }
      });
    }

    processRecording() {
      this.audioBlob = new Blob(this.audioChunks, { type: "audio/webm" });
      this.audioFile = new File([this.audioBlob], "recording.webm", { type: "audio/webm" });
      this.audioChunks = [];
  
      if (this.mediaRecorder.stream) {
        this.mediaRecorder.stream.getTracks().forEach(track => track.stop());
      }
  
      if (this.connectionActive && this.ws?.readyState === WebSocket.OPEN) {
        this.ws.send(this.audioFile);
      }
    }

    async transcribeFullAudio(setTranscriptText) {
      return new Promise(async (resolve, reject) => {
        if (!this.audioFile) {
          return reject(new Error("No audio file to transcribe"));
        }

        const apiUrl = process.env.REACT_APP_TRANSCRIPTION_URL 
          ? process.env.REACT_APP_TRANSCRIPTION_URL 
          : `${this.baseURL}/transcribe`;
    
        const formData = new FormData();
        formData.append("files", this.audioFile);
    
        try {
          const response = await fetch(apiUrl, {
            method: "POST",
            body: formData,
          });
    
          if (!response.ok) {
            return reject(new Error(`HTTP error! Status: ${response.status}`));
          }
    
          const result = await response.json();

          if (result.length > 0 && result[0].text) {
            setTranscriptText(result[0].text);
            resolve("Transcription successful");
          } else {
            reject(new Error("Failed to get transcript from the API"));
          }
        } catch (error) {
          reject(new Error(`Error transcribing audio: ${error.message}`));
        }
      });
    }    
  }