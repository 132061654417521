import { CloseIcon } from "assets/index";

export const ModalHeader = ({ title, onClose }) => {
    return (
        <div className="header">
            <p>{title}</p>
            <button className="closeButton" onClick={onClose}>
                <img src={CloseIcon} alt="Close Icon" />
            </button>
        </div>
    );
}