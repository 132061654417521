import "./navbar.scss";
import { Avatar, HeroImage, Logo } from "assets/index";

export const Navbar = ({ hide }) => {
    return (
        <div className="navbar">
            <img src={HeroImage} className={`hero ${hide ? "hidden" : ""}`} alt="Hero" />
            <div className="subContainer">
                <div className="logoTextContainer">
                    <img src={Logo} alt="Logo" className="logo" />
                    <p>Quipo AI Scribe</p>
                </div>
                <div className="avatarContainer">
                    <img src={Avatar} alt="Avatar" className="avatar" />
                </div>
            </div>
        </div>
    );
}

export default Navbar;